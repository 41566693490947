<template>
  <div class="parking-box">
    <div class="header">
      <img
        @click="goback"
        class="back"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
        alt=""
      />
      <div class="title">快速挪车</div>
    </div>
    <div class="content">
      <div class="command">
        <p class="com-title">快捷指令</p>
        <div class="com-list">
          <ul class="list-ul">
            <li
              v-for="(item, index) in comList"
              :key="index"
              @click="handleCommand(item.name, index)"
              :class="item.flag ? 'li-item li-active' : 'li-item'"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="other-command">
        <p class="other-title">
          输入其他指令
          <span class="limit">(30字以内)</span>
        </p>
        <div class="input-wrapper" @click="changeCommand">
          <input
            ref="input"
            type="text"
            placeholder="请输入指令"
            maxlength="30"
            v-model="value"
          />
          <img
            class="clearIcon"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/clear.png"
            alt=""
            v-if="showClear"
            @click="value = ''"
          />
        </div>
        <button
          :class="
            value === '' && listItem === ''
              ? 'send'
              : clickStatus == true && listItem === ''
              ? 'send'
              : 'send send-active'
          "
          @click="handleSend()"
          :disabled="value == '' && listItem === '' ? true : clickStatus"
        >
          发送
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { moveMissionTypeList, moveMissionDispatch } from '@/api/api';

export default {
  name: 'parking',
  components: {},
  data() {
    return {
      value: '', // input value值
      comList: [
        // { name: '1', flag: false },
        // { name: '2', flag: false },
        // { name: '3', flag: false },
      ], // 微动指令列表
      clickStatus: false, // 15s之后得点击状态
      listItem: '', // 存储点击列表得值
    };
  },

  created() {
    // const u = navigator.userAgent;
    // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    // if (isiOS) {
    //   // ios情况下键盘弹起禁止滚动
    //   document.body.addEventListener(
    //     "touchmove",
    //     function (e) {
    //       e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
    //     },
    //     { passive: false }
    //   );
    // }
  },

  computed: {
    showClear() {
      return this.value !== '';
    },
  },
  mounted() {
    this.getParkingList();
  },
  methods: {
    changeCommand() {
      this.$refs.input.focus();
      // const u = navigator.userAgent;
      // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // if (isiOS) {
      //   // ios情况下键盘弹起禁止滚动
      //   document.body.addEventListener(
      //     "touchmove",
      //     function (e) {
      //       e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
      //     },
      //     { passive: false }
      //   );
      // }
      this.listItem = '';
      this.comList.map((ite, ind) => {
        ite.flag = false;
      });
    },
    goback() {
      window.location.href = 'neolix://parking_trim';
    },

    //获取列表
    getParkingList() {
      moveMissionTypeList({
        token: this.$route.query.token,
      }).then((res) => {
        let listArr = [];
        res.map((item) => {
          listArr.push({
            name: item,
            flag: false,
          });
        });
        this.comList = listArr;
      });
    },
    // 点击列表
    handleCommand(item, index) {
      if (this.clickStatus) {
        this.$toast(
          '请勿重复点击',
          { duration: 2000 },
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-fail.png'
        );
        return;
      }
      this.comList.map((ite, ind) => {
        if (index === ind) {
          ite.flag = !ite.flag;
          this.listItem = ite.flag ? item : '';
        } else {
          ite.flag = false;
        }
      });
    },
    // 发送
    handleSend() {
      this.clickStatus = true;
      moveMissionDispatch({
        vin: this.$route.query.vin,
        command: this.listItem ? this.listItem : this.value,
      })
        .then((res) => {
          this.handleMap();
          this.$toast(
            '已发送',
            { duration: 2000 },
            'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-suc.png'
          );
        })
        .catch((error) => {
          this.handleMap();
          this.$toast(
            error.msg,
            { duration: 2000 },
            'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-fail.png'
          );
        });
    },

    // 公用方法
    handleMap() {
      setTimeout(() => {
        this.clickStatus = false;
      }, 15000);
      setTimeout(() => {
        this.comList.map((ite, ind) => {
          ite.flag = false;
        });
        this.listItem = '';
      }, 2000);
    },
  },
};
</script>

<style>
* {
  -webkit-user-select: none; /*禁用手机浏览器的用户选择功能 */
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
body {
  width: 100%;
  height: 100vh;
}
body,
html {
  -webkit-overflow-scrolling: touch;
}
</style>

<style scoped lang="scss">
.parking-box {
  width: 100%;
  height: 100%;
  background: #ffffff;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  font-weight: 600;

  input,
  textarea {
    -webkit-user-select: auto; /*webkit浏览器*/
    margin: 0px;
    padding: 0px;
    outline: none;
  }
  .header {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .back {
      width: 0.64rem;
      height: 0.64rem;
      margin: 0.08rem 0.22rem;
    }
    .title {
      font-size: 0.3rem;
      color: #000000;
      letter-spacing: 0;
      font-weight: 500;
      text-align: center;
      width: 100%;
      min-width: 2rem;
      margin-right: 1.08rem;
    }
  }
  .content {
    width: 100%;
    margin-top: 0.8rem;
    padding: 0 0.32rem;
    .command {
      padding-top: 0.4rem;
      .com-title {
        font-size: 0.32rem;
      }
      .com-list {
        margin-top: 0.28rem;
        .list-ul {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .li-item {
            width: 2.18rem;
            height: 0.72rem;
            background: #ffffff;
            border: 0.02rem solid rgba(204, 204, 204, 1);
            border-radius: 0.08rem;
            font-size: 0.28rem;
            text-align: center;
            line-height: 0.72rem;
            margin-bottom: 0.16rem;
            font-weight: normal;
            margin-right: 0.1rem;
          }
          .li-active {
            border: 0.02rem solid rgba(67, 110, 255, 1);
            color: #436eff;
          }
        }
      }
    }
    .other-command {
      margin-top: 0.32rem;
      .other-title {
        font-size: 0.32rem;
        .limit {
          font-size: 0.24rem;
          font-weight: 400;
          margin-left: 0.12rem;
        }
      }
      .input-wrapper {
        position: relative;
        width: 100%;
        margin-top: 0.28rem;
        width: 6.86rem;
        height: 1.08rem;
        input {
          width: 100%;
          height: 100%;
          background: #f4f7f9;
          border-radius: 0.12rem;
          font-size: 0.32rem;
          padding-left: 0.28rem;
          padding-right: 1.14rem;
          &::placeholder {
            color: #9798ab;
          }
        }
        .clearIcon {
          width: 0.4rem;
          height: 0.4rem;
          position: absolute;
          right: 0.24rem;
          top: 0.34rem;
        }
      }
      .send {
        width: 5.9rem;
        height: 0.92rem;
        background: #cbd5fb;
        border-radius: 0.46rem;
        font-size: 0.32rem;
        color: #ffffff;
        text-align: center;
        display: block;
        margin: 0.8rem auto 0;
        padding-top: 0.05rem;
      }
      .send-active {
        background: #436eff;
      }
    }
  }
}
</style>
